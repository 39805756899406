<template>
  <div class="landing">
    <section class="landing__showcase">
      <nav class="nav">
        <div class="nav__logo">
          <img src="@/assets/landing/logo@2x.png" alt="sdc21">
        </div>
        <div class="nav__btn-wrap">
          <a href="https://www.samsungdeveloperconference.com/flow/samsung/sdc21/main/page/portal?utm_source=button&utm_medium=page_button&utm_campaign=ctabutton_20211007" class="nav__btn" target="_blank">Go to SDC21 <img src="@/assets/landing/icon-bold-action-outlink@2x.png" alt=""></a>
        </div>
      </nav>
      <div class="landing__showcase-content">
        <div class="landing__showcase-img">
          <canvas />
          <video muted playsinline>
            <source src="@/assets/landing/showcase.mp4"
            type="video/mp4">
            showcase image
          </video>
        </div>
        <div class="landing__showcase-title"><span>#</span>mySDCstack</div>
        <div class="landing__showcase-desc">
          <h2>Create your SDC21 <br class="only-mo">photo profile!</h2>
          <p>Introduce yourself to fellow devs <br class="only-mo">ahead of SDC21. Then on Oct. 26, join <br class="only-mo">us online <br class="only-pc">to learn how to stack <br class="only-mo">Samsung tech and services for <br class="only-mo">incredible experiences.</p>
        </div>
      </div>
    </section>

    <section class="landing__link">
      <h3>Craft your <br class="only-mo">SDC stack</h3>
      <div class="landing__link-image">
        <div class="landing__link-sticker--item"></div>
        <div class="landing__link-sticker--item"></div>
        <img src="@/assets/landing/sample-stack.png" alt="">
      </div>
      <div class="landing__link-box--a">
        <div class="landing__link-desc">
          <p>#mySDCstack gives you a chance to see the faces <br class="only-pc">and personalities of SDC fans and attendees like yourself.</p>
        </div>
        <router-link class="landing__link-btn" to="/create">Create <span>#</span>mySDCstack <img src="@/assets/landing/icon-bold-action-outlink@2x.png" alt="camera-link"></router-link>
      </div>
      <div class="landing__link-box--b">
        <h4>What Kind of <br class="only-mo">SDC dev are you?</h4>
        <div class="landing__link-sticker">
          <ul>
            <li
              v-for="idx in data.lottieItems.length - 2"
              :key="`sampleSticker-${idx}`"
              class="landing__link-sticker--item"
            />
          </ul>
        </div>
        <div class="landing__link-desc">
          <p>There are 5 kinds of SDC developers. Take the quiz to find out <br class="only-pc">your type and then use those stickers in your stack!</p>
        </div>
        <a href="https://content.leadquizzes.com/lp/DURVfbWv_Q" class="landing__link-btn" target='_blank'>Take the quiz <img src="@/assets/landing/icon-bold-action-outlink@2x.png" alt="camera-link"></a>
      </div>
    </section>

    <section class="landing__gallery">
      <div class="landing__gallery-title"><span>#</span>mySDCstack Gallery</div>
      <div class="landing__gallery-items">
        <StackItem v-for="(stack, idx) in data.stacksList" :key="`${data.pageCount}-${idx}`" :stack="stack" />
      </div>
      <div class="landing__gallery__pagination">
        <button :disabled="data.pageCount === 1" class="landing__gallery-btn--back" @click="methods.loadPrev()">
          <img v-if="data.pageCount === 1" src="@/assets/landing/indicator-common-2-arrow-contained-a-left-dark-bg-disabled@2x.png" alt="back">
          <img v-else src="@/assets/landing/indicator-common-2-arrow-contained-b-right-dark-bg-hover@2x.png" alt="back" :style="{ 'transform': 'rotate(180deg)' }">
        </button>
        <div class="landing__gallery-btn--count">
          {{data.pageCount}}/{{data.totalPages}}
        </div>
        <button :disabled="data.pageCount === data.totalPages" class="landing__gallery-btn--next" @click="methods.loadNext()">
          <img v-if="data.pageCount === data.totalPages" src="@/assets/landing/indicator-common-2-arrow-contained-a-left-dark-bg-disabled@2x.png" alt="next" :style="{ 'transform': 'rotate(180deg)'}">
          <img v-else src="@/assets/landing/indicator-common-2-arrow-contained-b-right-dark-bg-hover@2x.png" alt="next">
        </button>
      </div>
    </section>

    <section class="landing__info">
      <div class="landing__info-title"><span>#</span>mySDCstack Giveaway</div>

      <div class="landing__info-box">
        <div class="landing__info-title--sub">[Prize]</div>
        <div class="landing__info__prize">
          <div v-for="({asset, alt, title, color, cssClass}, index) in data.prizes" class="landing__info__prize-item" :key="index">
            <div :class="['landing__info__prize-img', cssClass]">
              <svg class="landing__info__prize-bg" :fill="color" viewBox="0 0 493 493" xmlns="http://www.w3.org/2000/svg">
                <g>
                <path d="M488.5,173c-6.1-45.8-22.3-87.3-50.8-117.7h0C407.3,26.8,365.8,10.6,320,4.5c-43.7-5.8-73.5-4.4-73.5-4.4
                S216.7-1.3,173,4.5c-45.8,6.1-87.3,22.3-117.6,50.8C26.8,85.7,10.6,127.2,4.5,173c-5.8,43.7-4.4,73.5-4.4,73.5s-1.4,29.8,4.4,73.5
                c6.1,45.8,22.3,87.3,50.8,117.7c30.4,28.6,71.9,44.8,117.6,50.9c43.7,5.8,73.5,4.4,73.5,4.4s29.8,1.4,73.5-4.4
                c45.8-6.1,87.3-22.3,117.6-50.9h0c28.6-30.4,44.8-71.9,50.8-117.7c5.8-43.7,4.4-73.5,4.4-73.5S494.3,216.7,488.5,173"/>
                </g>
              </svg>
              <img :src="asset" :alt="alt">
            </div>
            <div class="landing__info__prize-item--desc">
                {{title}}
            </div>
          </div>
        </div>
      </div>

      <div class="landing__info-card">
        <div class="landing__info-section--left">
          <div class="landing__info-desc">Thank you to everyone who participated in the <strong>#mySDCstack</strong> event!</div>
          <div class="landing__info-desc">If you still want to make and share your own photo profile, you can until Nov. 25!</div>
          <div class="landing__info-title--sub">[ Period ]</div>
          <div class="landing__info-desc landing__info-desc--lg">Oct. 6 - Oct. 29, 2021 (PT)</div>
        </div>

        <div class="landing__info-section--right">
          <div class="landing__info-title--sub">[How to participate]</div>
          <div class="landing__info-desc">1. Click the “Create #mySDCstack” button.</div>
          <div class="landing__info-desc">2. Flex your creative talents and use AR stickers to create your own SDC stack.
            <div class="landing__info-desc--sub">*Wondering what your SDC Developer type is? Take the quiz before making your SDC stack!</div>
          </div>
          <div class="landing__info-desc">3. Agree to the terms and share your SDC stack with the required hashtags on social media. <br class="only-pc">(Twitter, Instagram, Facebook or LinkedIn)<br>Required hashtags: <strong>#mySDCstack #SDC21 #Samsung</strong> + add your developer type: <br class="only-mo">#TheWiseWizard, #TheMightyMaster, #TheValiantChampion, #TheRadicalRebel, #TheMadScientist</div>
          <div class="landing__info-desc">
              4. For a better chance at winning, log in to the SDC21 website and collect the #mySDCstack badge in the Badge Collection event.
            <div class="landing__info-desc--sub">*If you participate, your personalized SDC stack will be shared on this page and you will have a chance to win a prize.</div>
          </div>
          <div class="landing__info-title--sub">[Don't need a prize?]</div>
          <div class="landing__info-desc">Even if you don't participate, you can download your SDC stack and/or share it to social media.</div>
        </div>
      </div>
    </section>

    <section class="landing__footer">
      <p class="landing__footer-title"><strong>[General Terms &amp; Conditions for the Promotion]</strong></p>
      <div class="landing__footer-desc">
        <p>1. Scope: This General Terms &amp; Conditions for the Promotion (“General Terms and Conditions”) is a legal agreement between you (“Participant” or “You”) and Samsung Electronics Co., Ltd (“Samsung”) for promotion campaigns and events related to mySDCstack (“Promotion”).<br><br></p>
        <p>2. Eligibility: The Promotion is open to lawful, permanent, legal residents or citizens of most of countries except troubled areas. And DO NOT ENTER THIS PROMOTION IF YOU ARE OTHERWISE PROHIBITED OR RESTRICTED BY LAW TO PARTICIPATE IN THIS PROMOTION. Participants in those countries can participate and share mySDCstack, but participants are excluded from receiving prizes. Participants must also be at least 18 years of age or the age of majority in their place of residence, whichever is older. No employee, contractor, director, or officer of Samsung, its parent, affiliates, subsidiaries, distributors, sales representatives, retailers, and advertising, promotion, and judging agencies and all other service agencies involved with the Promotion, and members of the immediate family (spouse, parent, child, sibling, and their respective spouse), and household of each such employee is eligible to participate in the Promotion. Eligibility to participate in the Promotion void where prohibited or restricted.<br><br></p>
        <p>3.Entry: All Entries and related information must be submitted in English. By participating, Participants agree to these General Terms and Conditions, which are final and binding on Participants in all respects. Entries generated by script, macro, or other automated means are void and may cause disqualification. Entries not properly entered into the Promotion will not be acknowledged. Entries must be received by Samsung during the Promotion Period. Only eligible Entries actually received by Samsung in accordance with the timing requirements set forth above will be judged and eligible to win. Samsung is not responsible for (i) inaccessible, lost, late, misdirected, mutilated, damaged, irregular, altered, counterfeit, fraudulent, incomplete, or illegible Entries, which are void, or (ii) any computer-related, online, telephonic, or technical malfunctions that may occur. Illegible, unintelligible, inaccessible, non-public, incomplete, garbled, or mutilated Entries will be disqualified. Samsung reserves the right, in its sole discretion, to disqualify any Entries not in compliance with these General Terms and Conditions.<br><br></p>
        <p>4. Prize: The prizes are 5 each of 32” M7 4K UHD Smart Monitors, Galaxy Watch4s, Galaxy SmartTag 2-packs, Galaxy Buds2, and Portable SSD T7 Touch 2TBs. Limit of one prize per winner. The prizes will be awarded at random and are nontransferable. Winners cannot choose the color, design, model or type of the prize they won. The prize is non-refundable and no cash alternative is available, except in Samsung’s sole discretion.  Samsung reserves the right, in its sole discretion, to modify or substitute the prize at any time if the prize advertised is unavailable.<br><br></p>
        <p>5. Winner: The Winner will be chosen and notified by email.  If a potential Winner (a) does not respond within seven (7) days of the date on which Samsung notifies participant that he/she is a winner; (b) is not in compliance with these General Terms and Conditions; (c) does not meet the eligibility requirements; or (d) declines the prize, participant will be disqualified and Samsung reserves the right to reallocate the prize to an alternate winner. Samsung is not responsible for any change of email address, mailing address, and/or telephone number of participants. All properly claimed prizes will be awarded, but in no event will Samsung award more prizes than are provided for in these General Terms and Conditions. If the delivery to the Winner’s address is prohibited or restricted by law, Samsung may choose an alternative Winner.<br><br></p>
        <p>6. Conditions:</p>
        <p>• Acceptance of prize constitutes permission for Samsung to use Winner’s name, likeness, and entry for purposes of advertising related to SDC21 without further compensation unless prohibited by law.</p>
        <p>• Acceptance of prize constitutes permission for Samsung to collect the Winner’s personal data needed to send prize to Winner.</p>
        <p>• Winner reserves the right to refuse to provide personal data in which case Winner will lose Winner status and accept that they will not be getting a prize from Samsung.</p>
        <p>• If the personal data provided by Winner is not accurate, Samsung makes no warranty that the prize will be delivered to Winner.</p>
        <p>• #mySDCstack Campaign Privacy Notice(https://sdc21mystack.com/?view=privacy) also applies to your use of #mySDCstack Campaign, which shall always prevail over the Terms in relation to how we use your information for #mySDCstack Campaign.</p>
        <p>• Prizes will be sent out to Winners after SDC21 and the #mySDCstack campaign and related events are over.</p>
        <p>•  Please allow up to thirty (30) calendar days for delivery of the prize. Delivery is subject to the winners providing shipping details and any other necessary information on request. If a prize is shipped, Samsung is not responsible for any prize that is lost, stolen, mutilated, destroyed, misdirected, or undelivered for any reason. Samsung will not replace any lost or stolen prize items.</p>
        <p>• Prizes are awarded at Samsung’s sole discretion. Samsung reserves the right at its sole discretion to disqualify any person it finds to be tampering or to have tampered with the operation of the promotion or the promotion website, or to be acting in violation of these terms and conditions. In the event that any giveaway winner is subsequently disqualified from a giveaway for any reasons, Samsung, in its sole discretion, may decide whether a replacement winner should be selected in accordance with the selection criteria.</p>
        <p>• By entering a giveaway, you hereby warrant that: (1) all information submitted is true, accurate, current and complete; (2) you have such permission and that to the best of your knowledge your entries do not infringe any third party’s intellectual property rights, proprietary rights, or rights of confidentiality; and (3) your entry or response will not infringe any third-party’s rights of publicity or privacy, and will not contain any libellous, defamatory, obscene, indecent, harassing or threatening material. Samsung reserves the right, in its sole discretion, to delete any entry without the prior notification to the participant found to be inappropriate or otherwise suspected of infringing any third party intellectual property rights. Samsung cannot accept responsibility for errors, loss, and damage to entries outside of Samsung’s reasonable control. Samsung reserves the right to verify the eligibility of participants and disqualify a participant for submitting inaccurate or false information.</p>
        <p>• Participants’ personal information and data will only be used in accordance with our Privacy Policy. Any third-party website used but owned independently have their own separate privacy and data collection policies and practices. Any data you provide to these websites will be governed under the terms of their privacy policy. Samsung has no responsibility whatsoever for the independent actions or policies of these independent sites and is not responsible for content or privacy practices of such websites.</p>
        <p>• Samsung shall not be liable to Winner or any other party for any claim, loss or damage, direct or indirect, including, without limitation, compensatory, consequential, incidental, indirect, special, examplary, or punitive damages, regardless of the form of action or basis of any claim.</p>
        <p>• Samsung reserves the right to correct any errors, inaccuracies or omissions, and to change, modify or update information related to #mySDCstack campaign and to these terms.</p>
      </div>
      <div class="landing__footer-policy">
        <p
          class="landing__footer-pop"
          @click="methods.showModal"
        >[Privacy Notice]</p>
        <div class="landing__footer-contact">
          <p>For event-related inquiries, please contact us</p>
          <a href="mailto:sdcsupport@samsungdevelopers.com">sdcsupport@samsungdevelopers.com</a>
        </div>
      </div>
    </section>

    <transition name="modal">
      <div
        v-if="data.isShowModal"
        ref="modal"
        class="landing__modal"
        @click="methods.hideModal"
      >
        <PrivacyPolicy
          @click.prevent.stop
        >
          <button
            class="landing__modal-close"
            @click="methods.hideModal"
          >Close</button>
        </PrivacyPolicy>
      </div>
    </transition>

    <ScrollBtn />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, reactive, nextTick } from 'vue'
import axios from 'axios';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue';
import StackItem from '@/components/StackItem.vue';
import ScrollBtn from '@/components/ScrollBtn.vue';

import smartMonitor from '@/assets/landing/32-m-7-4-k-uhd-smart-monitor-1-copy@2x.png';
import galaxyWatch from '@/assets/landing/galaxy-watch-4-1-copy@2x.png';
import smartTag from '@/assets/landing/galaxy-smart-tag-2-pack-copy@2x.png';
import galaxyBuds from '@/assets/landing/galaxy-buds-2-copy@2x.png';
import portableSSD from '@/assets/landing/portable-ssd-t-7-touch-2-tb-2-copy@2x.png';

import lottie from 'lottie-web'

export default {
  name: 'landing',
  components: {
    PrivacyPolicy,
    StackItem,
    ScrollBtn
  },
  props: {
    query: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const data = reactive({
      stacksList: null,
      testList: null,
      limit: 0,
      pageCount: 1,
      totalPages: 0,
      windowWidth: 0,
      pcOnly: false,
      prizes: [
        {
          asset: smartMonitor,
          alt: "32” M7 4K UHD Smart Monitor",
          title: "32” M7 4K UHD Smart Monitor (5)",
          color: '#0077c8',
          cssClass: 'landing__info__prize-img--landscape'
        },
        {
          asset: galaxyWatch,
          alt: "Galaxy Watch4",
          title: "Galaxy Watch4 (5)",
          color: "#00b3e3",
          cssClass: 'landing__info__prize-img--portrait'
        },
        {
          asset: smartTag,
          alt: "Galaxy SmartTag 2-pack",
          title: "Galaxy SmartTag 2-pack (5)",
          color: "#97d653",
          cssClass: 'landing__info__prize-img--landscape'
        },
        {
          asset: galaxyBuds,
          alt: "Galaxy Buds2",
          title: "Galaxy Buds2 (5)",
          color: "#8093dc",
          cssClass: 'landing__info__prize-img--portrait'
        },
        {
          asset: portableSSD,
          alt: "Portable SSD T7 Touch 2TB",
          title: "Portable SSD T7 Touch 2TB (5)",
          color: "#ffb546",
          cssClass: 'landing__info__prize-img--landscape'
        },
      ],
      isShowModal: false,
      lottieItems: ['3-1', '3-10', '3-1', '3-2', '3-3', '3-4', '3-5']
    })


    const fetchData = async () => {
      const res = await axios.get(`/users/${data.limit}/${data.pageCount}`);
      data.stacksList = res.data.items;
      data.totalPages = res.data.meta.totalPages;
    }

    const methods = {
      loadPrev: () => {
        data.pageCount -= 1;
        fetchData();
      },
      loadNext: () => {
        data.pageCount += 1;
        fetchData();
      },
      setResize: () => {
        data.windowWidth = document.documentElement.clientWidth;

        if (data.windowWidth <= 600 && data.pcOnly === true) {
          data.pcOnly = false;
        } else if (data.windowWidth > 600 && data.pcOnly === false) {
          data.pcOnly = true;
        }

        if (data.windowWidth <= 600 && data.limit !== 8) {
          data.limit = 8;
          console.log('mobile limit, pageCount 📲📲📲', data.limit, data.pageCount);
          fetchData();
        } else if (data.windowWidth > 600 && data.limit !== 12) {
          data.limit = 12;
          console.log('desktop limit, pageCount 💻💻💻', data.limit, data.pageCount);
          fetchData();
        }
      },
      init: () => {
        data.windowWidth = document.documentElement.clientWidth;

        if (data.windowWidth <= 600) {
          data.limit = 8;
          data.pcOnly = false;
          console.log('mobile limit, pageCount 📲📲📲', data.limit, data.pageCount);
          fetchData();
        } else if (data.windowWidth > 600) {
          data.limit = 12;
          data.pcOnly = true;
          console.log('desktop limit, pageCount 💻💻💻', data.limit, data.pageCount);
          fetchData();
        }
      },
      showModal: () => {
        data.isShowModal = true
        nextTick(() => {
          // disableBodyScroll(document.querySelector('.landing__modal .container__body'))
        })
      },
      hideModal: () => {
        data.isShowModal = false
        // enableBodyScroll(document.querySelector('.landing__modal .container__body'))
      }
    }

    onMounted(async () => {
      if (props.query && props.query === 'privacy') {
        methods.showModal()
      }

      const stickerWrapper = document.querySelectorAll('.landing__link-sticker--item')

      data.lottieItems.forEach((id, idx) => {
        lottie.loadAnimation({
          container: stickerWrapper[idx],
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: require(`@/assets/stickers/${id}.json`)
        })
      })

      methods.init();
      console.log('first data limit, pageCount 🚀🚀🚀', data.limit, data.pageCount);

      const res = await axios.get(`/users/${data.limit}/${data.pageCount}`)
      data.stacksList = res.data.items;
      data.totalPages = res.data.meta.totalPages;
      window.addEventListener("resize", methods.setResize);

      const canvas = document.querySelector('canvas')
      const ctx = canvas.getContext('2d')
      const video = document.querySelector('video')
      const canvasPlay = () => {
        if (video.currentTime >= video.duration || video.ended) {
          video.currentTime = video.duration
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          return
        }
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        requestAnimationFrame(canvasPlay)
      }
      const readVideo = () => {
        if (video.readyState >= 2) {
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          !video.played && video.play()
          requestAnimationFrame(canvasPlay)
        } else {
          video.pause()
          video.currentTime = 0
        }
      }
      readVideo()
      video.addEventListener('loadeddata', readVideo)
      video.play()
    })

    onUnmounted(() => {
      // clearAllBodyScrollLocks()
      window.removeEventListener("resize", methods.setResize);
    })

    return {
      data,
      methods,
    }
  }
}
</script>

<style scoped lang="scss" src="@/css/landing.scss"></style>
