<template>
  <div class="container" :class="{korean: state.country === 'korea'}">
    <slot />
    <div class="container__body">
      <h1 v-if="state.country === 'us'">#mySDCstack Campaign U.S. Privacy Notice</h1>
      <h1 v-else-if="state.country === 'korea'">개인정보처리방침 (#mySDCstack 캠페인용)</h1>
      <h1 v-else>#mySDCstack Campaign Privacy Notice</h1>
      <div class="select-country">
        <button
          class="select-country__current"
          @click="isSelectorOpen = !isSelectorOpen"
        >
          <span>{{ state.countryOption.find(item => item.key === state.country).value }}</span>
          <img v-if="isSelectorOpen" class="select-country__current-icon" src="@/assets/landing/icon-arrow-close.png">
          <img v-else class="select-country__current-icon" src="@/assets/landing/icon-arrow.png">
        </button>
        <ul
          v-show="isSelectorOpen"
          class="select-country__option"
        >
          <li v-for="(item, idx) in state.countryOption">
            <span
              :class="{ active: state.country === item.key }"
              @click="() => {
                isSelectorOpen = false
                if (state.country !== item.key) {
                  commit('changeCountry', item.key)
                }
              }"
            >{{ item.value }}</span>
          </li>
        </ul>
      </div>
      <p v-if="state.country === 'korea'" class="effective">EFFECTIVE : 07/10/2021</p>
      <p v-else class="effective">EFFECTIVE : 06/10/2021</p>
      <template v-if="state.country === 'us'">
        <div class="summary">
          <p>Samsung Electronics Co., Ltd., along with our affiliates and subsidiaries (collectively, “<strong>Samsung</strong>”) respect your concerns about privacy. This #mySDCstack Campaign Privacy Notice applies to the personal information we obtain through #mySDCstack Campaign. It does not apply to information collected by other apps, websites or services provided by Samsung.</p>
          <p>This Privacy Notice provides information about how may collect, use and share your personal information in connection with #mySDCstack Campaign. Our Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> (“<strong>Samsung Privacy Policy</strong>”) will also apply to your use of #mySDCstack Campaign, and should be read in conjunction with this Privacy Notice. The Samsung Privacy Policy provides additional information about our personal information practices, including how to contact us. In the event any portion of this Privacy Notice conflicts with the Samsung Privacy Policy the relevant provisions of this Privacy Notice will govern.</p>
        </div>

        <h2>Information We Obtain</h2>
        <p>Through #mySDCstack Campaign, we may obtain information about you in various ways. The types of personal information we may obtain include:</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information We Obtain By Automated Means</h3>
        <ul>
          <li>We may collect data about your use of #mySDCstack Campaign, including the time and duration, visited pages and clicked components and information about your device settings.</li>
        </ul>
        <p>We also may collect other information about you, your devices and apps, and your use of #mySDCstack Campaign in ways that we describe to you at the time of collection or otherwise with your consent.</p>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer #mySDCstack Campaign. To learn more about Google Analytics, please visit https://www.google.com/policies/privacy/partners/.</p>

        <h2>How We Use the Information We Obtain</h2>
        <h3>We may use the information we obtain through #mySDCstack Campaign:</h3>
        <ul>
          <li>To provide you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and the Samsung Privacy Policy for the U.S;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>

        <h2>Information Sharing</h2>
        <p>As stated above, this Privacy Notice supplements the Samsung Privacy Policy (available at <a href="https://www.samsung.com/us/account/privacy-policy" target="_blank">https://www.samsung.com/us/account/privacy-policy</a>), which describes our information sharing practices.</p>
        <h3>Notice To California Residents</h3>
        <p>If you are a California consumer, for more information about your privacy rights, please see the section of the Samsung Privacy Policy called “California Consumer Privacy Statement” available at <a href="https://www.samsung.com/us/account/privacy-policy" target="_blank">https://www.samsung.com/us/account/privacy-policy.</a></p>
        <h3>Updates to This Privacy Notice</h3>
        <p>We will update this Privacy Notice to let you know if we change how we collect and process your information in #mySDCstack Campaign, if we’re required by law. The date when the document was last updated is shown at the top of this Privacy Notice.</p>
      </template>
      <template v-else-if="state.country === 'korea'">
        <div class="summary">
          <p>'삼성전자 주식회사'(이하 '회사'라 합니다)는 이용자의 개인정보를 중요시하며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」을 준수하기 위하여 노력하고 있습니다.</p>
          <p>회사는 이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보 보호를 위해 어떠한 조치를 취하고 있는지 개인정보처리방침을 통해 알려드립니다.</p>
        </div>

        <h2>0. 총칙</h2>
        <p>“개인정보”란 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등에 의하여 특정 개인을 알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함)를 말합니다.</p>
        <p>회사는 이용자가 본 개인정보처리방침을 언제나 쉽게 볼 수 있도록 #mySDCstack 캠페인 홈페이지 (<a href="http://www.sdc21mystack.com" target="_blank">http://www.sdc21mystack.com</a>)의 첫 화면에 공개하고 있습니다.</p>
        <p>회사는 개인정보처리방침을 지속적으로 개선하기 위하여 개정에 필요한 절차를 정하고 있습니다. 또한 개인정보처리방침을 개정하는 경우 이에 버전번호 등을 부여하여 이용자가 개정된 사항을 쉽게 알아볼 수 있게 합니다.</p>
        <p>본 방침은 2021년 10월 7일부터 시행되며, 이를 개정하는 경우 웹사이트에 공지(또는 서면·이메일 등의 방법으로 개별 공지)합니다.</p>

        <h2>1. 이용자 및 법정대리인의 권리 및 그 행사 방법</h2>
        <p>(1) 이용자 및 법정대리인은 등록되어 있는 이용자 본인 혹은 당해 만 14세 미만자의 개인정보와 관련하여, 다음과 같은 사항에 대하여 열람, 정정·삭제, 처리정지, 혹은 동의 철회를 언제든지 요청할 수 있습니다.</p>
        <p>– 회사가 가지고 있는 개인정보: (이름, 이메일, 국가정보, 홈페이지 이용 기록, 소셜미디어 ID, 닉네임, 주소, 휴대폰 번호) <br>– 회사가 개인정보를 이용하거나 제3자에게 제공한 현황: (홈페이지 이용 기록) <br>– 이용자가 회사의 개인정보 수집∙이용∙제공 등에 동의를 한 현황: (소셜미디어 ID, 닉네임, 주소, 휴대폰 번호)</p>
        <p>이를 위하여 홈페이지의 “개인정보변경”(또는 “회원정보수정” 등) 메뉴 또는 대표 이메일계정 (sdcsupport@samsungdevelopers.com)를 이용하거나,  개인정보 보호책임자에게 서면·이메일로 연락할 수 있습니다.</p>
        <p>회사는 다음에 해당하는 경우 개인정보의 전부 또는 일부에 대하여 열람 혹은 정정·삭제 요청을 거부할 수 있습니다.</p>
        <p>– 법률에 따라 열람이 금지되거나 제한되는 경우 <br>– 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우</p>
        <p>2) 이용자가 개인정보의 오류에 대해 정정을 요청한 경우, 회사는 정정을 완료하기 전까지 해당 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 또한 오류가 있는 개인정보를 제3자에게 이미 제공한 경우에는 정정된 내용을 제3자에게 지체 없이 통지합니다.</p>
        <p>회사는 이용자 혹은 법정대리인의 요청에 의해 삭제 또는 처리 정지된 개인정보를 “7. 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고, 그 외의 용도로 열람 또는 이용할 수 없게 합니다.</p>
        <p>이용자는 자신의 개인정보를 최신의 상태로 정확하게 입력해야 합니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며, 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.</p>
        <p>이용자에게는 개인정보를 보호받을 권리뿐만 아니라 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 있습니다. 비밀번호 등 이용자의 개인정보가 유출되지 않도록 조심하고, 게시물 등 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 이 같은 책임을 다하지 않고 타인의 정보 및 존엄성을 훼손할 시에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등에 의해 처벌 받을 수 있습니다.</p>

        <h2>2. 개인정보 보호책임자 및 고객 지원 담당 부서</h2>
        <p>회사는 이용자의 개인정보를 보호하고 개인정보와 관련된 불만을 처리하기 위하여 아래와 같이 담당 부서 및 개인정보 보호책임자를 지정하고 있습니다.</p>
        <h3>(1) 고객 지원 담당 부서</h3>
        <p>고객 지원 담당 부서 : 삼성전자 글로벌마케팅센터 브랜드전략그룹 <br>문의처 : sdcsupport@samsungdevelpoers.com</p>
        <h3>(2) 개인정보 보호책임자 및 개인정보 보호 담당 부서</h3>
        <p>개인정보 보호책임자 : 개인정보보호사무국장 <br>개인정보 보호 담당 부서 : 개인정보보호사무국 <br>전화 : 1588-4730 <br>문의처 : privacy.sec@samsung.com</p>
        <h3>(3) 기타 기관</h3>
        <p>이용자는 회사의 서비스를 이용하며 발생한 개인정보 보호와 관련된 모든 민원을 개인정보 보호책임자 혹은 담당 부서로 신고할 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다. 기타 개인정보 침해에 대해 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다.</p>
        <p>– 개인정보침해 신고센터 (https://privacy.kisa.or.kr / 국번 없이 118) <br>– 대검찰청 과학수사부 사이버수사과 (http://cybercid.spo.go.kr / 국번 없이 1301) <br>– 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 국번 없이 182)</p>
        
        <h2>3. 개인정보 자동 수집 장치의 설치, 운영, 거부에 관한 사항 <span>(정보통신망 이용촉진 및 정보보호 등에 관한 법률 적용대상인 경우에 한하여 적용됩니다)</span></h2>
        <p>회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 사용합니다. 쿠키란 회사의 웹사이트 운영에 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로, 이용자의 컴퓨터 하드디스크에 저장됩니다.</p>
        <h3>(1) 쿠키 사용 목적</h3>
        <p>회사는 자동로그인 기능 구현, 회원과 비회원의 접속 빈도나 방문 시간 등 분석, 이용자의 취향과 관심분야 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공의 목적으로 쿠키 등을 사용합니다.</p>
        <h3>(2) 쿠키 설정 방법</h3>
        <p>이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인하거나, 모든 쿠키의 저장을 거부할 수 있습니다. <br>단, 이용자가 쿠키 설치를 거부할 경우 서비스 제공에 어려움이 있을 수 있습니다.</p>
        <p>– 쿠키 설정 방법 (인터넷 익스플로러 11.0을 사용하고 있는 경우) <br>: 「도구」메뉴에서 「인터넷옵션」을 선택합니다. 「개인정보 탭」을 클릭합니다. <br>「고급」을 선택하여 자신에게 맞는 쿠키 허용 수준을 설정합니다. <br>– 받은 쿠키를 보는 방법 (익스플로러 11.0을 사용하고 있는 경우) <br>: 「도구」 메뉴에서 「인터넷옵션」을 선택합니다.  <br>「일반」 탭을 클릭합니다. <br>‘검색기록’ 의 「설정」으로 들어가서 「파일보기」에서 확인합니다. <br>– 쿠키 설치 거부 방법 (인터넷 익스플로어 11.0 사용하고 있는 경우) <br>: 「도구」 메뉴에서 「인터넷옵션」을 선택합니다.  <br>「개인정보 탭」 을 클릭합니다. <br>「기본값」에서 상위레벨을 선택하여 “모든 쿠키차단”으로 설정합니다.</p>

        <h2>4. 개인정보 수집 항목 및 수집 방법</h2>
        <h3>(1) 개인정보 수집 항목</h3>
        <p>화사는 최초 회원 가입 또는 서비스 이용 시 이용자로부터 아래와 같은 개인정보를 수집하고 있습니다.</p>
        <div class="table-wrap">
          <table>
            <thead>
              <tr>
                <th>구분</th>
                <th>자세한 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>① 필수항목</td>
                <td>□ 이름 <br>□ 이메일 <br>□ 국가정보 <br>□ 홈페이지 이용 기록</td>
              </tr>
              <tr>
                <td>② 선택항목</td>
                <td>□ 주소, 휴대폰 번호 <br>□ 소셜미디어 ID, 닉네임</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>※ 회사는 이용자의 사생활을 현저히 침해할 우려가 있는 민감정보(사상·신념, 노동조합·정당의 가입·탈퇴, 정치적 견해, 건강, 성생활 등에 관한 정보 등)는 수집하지 않습니다.</p>
        <p>※ 회사는 원칙적으로 이용자가 만 14세 미만자일 경우 개인정보를 수집하지 않습니다. 서비스 이용을 위하여 만 14세 미만자의 개인정보를 부득이 수집할 때에는 사전에 법정대리인에게 동의를 구하고, 관련 업무가 종료됨과 동시에 해당 정보를 지체 없이 파기하며, 업무가 진행되는 동안 개인정보를 철저히 관리합니다.</p>
        <h3>(2) 개인정보 수집 방법</h3>
        <p>- 홈페이지, 이메일, 상담게시판 등을 통한 수집, 경품행사 응모 및 이벤트 참여 등을 통한 수집 <br>- 생성정보 수집 툴을 통한 수집</p>
        <p>※ 회사는 이용자가 회사의 개인정보 수집·이용 동의서의 각 내용에 대해 "동의" 또는 "동의하지 않음"을 선택할 수 있는 절차를 갖추고 있습니다.</p>

        <h2>5. 개인정보의 수집∙이용 목적</h2>
        <p>회사는 수집한 개인정보를 다음의 목적을 위해 사용합니다.</p>
        <div class="table-wrap">
          <table>
            <thead>
              <tr>
                <th>목적</th>
                <th>자세한 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>① 본인확인</td>
                <td>□ 개인정보 처리 및 정보주체의 개인정보 열람, 정정, 삭제, 처리 정지 요구, 응모한 이벤트에 선정 시 경품 배송을 위해 배송지 주소와 휴대전화 번호 등 본인확인</td>
              </tr>
              <tr>
                <td>② 서비스 제공</td>
                <td>□ 기술 관련 문의 응대 제공 </td>
              </tr>
              <tr>
                <td>③ 고지 및 안내</td>
                <td>□ 삼성 개발자 컨퍼런스 개인정보 처리방침 및 약관 변경사항 고지</td>
              </tr>
              <tr>
                <td>④ 컨텐츠 관련 정보 제공</td>
                <td>□ 컨텐츠 및 행사 관련 소식 전달 </td>
              </tr>
              <tr>
                <td>⑤ 이벤트 홍보 등 홍보성 정보 전송</td>
                <td>□ 수집된 연락처를 통해 홍보성 정보 전송 가능</td>
              </tr>
              <tr>
                <td>⑥ 이용자 분석 및 서비스/웹사이트 개선</td>
                <td>□ 이용자 사용 패턴 분석을 통해 필요시 서비스 및 웹사이트에 대한 개선 조치 진행</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>6. 제3자에게의 개인정보 제공</h2>
        <p>회사는 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.</p>
        <p>– 법령의 규정에 의거한 경우, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 <br>– 유료 서비스 제공에 따른 요금 정산을 위하여 필요한 경우 <br>– 통계 작성, 학술연구, 시장조사를 위해 특정 개인을 식별할 수 없는 형태로 가공해서 제공하는 경우 <br>– 이용자가 사전에 동의한 경우</p>
        <p>① #mySDCstack 캠페인 참여 및 이벤트 응모 서비스</p>
        <div class="table-wrap">
          <table class="text-center">
            <thead>
              <tr>
                <th>제공받는 자</th>
                <th>제공되는 개인정보</th>
                <th>제공받는 자의 이용목적</th>
                <th>제공받는 자의 이용목적</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>제일기획</td>
                <td>4.(1)의 항목</td>
                <td>수집된 개인정보 저장 및 <br>이벤트 응모 상품 배송</td>
                <td>1년</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>위에 기재된 업체는 추후 변동될 수 있습니다. 업체가 변경되거나, 기재된 내용 외에 이용자의 개인정보를 제공 혹은 공유하는 경우에는 별도로 이용자 동의를 구한 후 해당 정보를 제공합니다.</p>

        <h2>7. 개인정보의 보유 및 이용 기간</h2>
        <p>회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다</p>
        <p>단, 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관련 법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. 이 경우 회사는 해당 정보를 별도의 데이터베이스(DB)로 옮기거나 다른 장소에 보관합니다.</p>
        <div class="table-wrap">
          <table class="text-center first-left">
            <thead>
              <tr>
                <th>보유 항목</th>
                <th>보유기간</th>
                <th>법적 근거</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>계약 또는 청약철회 등에 관한 기록</td>
                <td>5년</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                <td>5년</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                <td>3년</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td>신용정보의 수집/처리 및 이용 등에 관한 기록</td>
                <td>3년</td>
                <td>신용정보의 이용 및 보호에 관한 법률</td>
              </tr>
              <tr>
                <td>표시/광고에 관한 기록</td>
                <td>6개월</td>
                <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
              </tr>
              <tr>
                <td>이용자의 인터넷 등 로그 기록 / 이용자의 <br>접속지 추적자료 <br>그 외의 통신사실 확인 자료</td>
                <td>3개월 <br><br>12개월</td>
                <td>통신비밀보호법</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>8. 개인정보의 파기 절차 및 파기 방법</h2>
        <p>개인정보의 파기 절차 및 파기 방법은 다음과 같습니다.</p>
        <h3>(1) 파기 절차</h3>
        <p>이용자의 개인정보는 목적이 달성된 후 별도의 DB(종이의 경우 별도의 서류함)로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(“7. 개인정보의 보유 및 이용기간” 참조) 일정 기간 보관된 후 파기됩니다. 별도의 DB로 옮겨진 개인정보는 법령에 의한 경우 외에는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.</p>
        <h3>(2) 파기 방법</h3>
        <p>회사는 기록을 재생할 수 없는 기술적 방법을 사용하여 전자적 파일 형태로 저장된 개인정보를 삭제합니다. 종이에 출력된 개인정보는 분쇄나 소각을 통해 파기됩니다.</p>

        <h2>9. 개인정보의 위탁 및 국외 이전</h2>
        <p>회사는 서비스 제공을 위해 필요한 경우에 한해 아래와 같이 서비스 별로 개인정보의 처리 업무를 외부 전문 업체에 위탁합니다.</p>
        <p>회사는 개인정보의 처리를 위탁하는 경우 개인정보 보호에 만전을 기하기 위하여 개인정보 보호 관련 지시 엄수 의무, 개인정보에 대한 비밀 유지 의무, 제3자 제공 금지 및 사고 시 책임부담 의무, 위탁기간 이후 및 처리 종료 후 개인정보의 반환 또는 파기 의무 등을 명확히 규정하고, 수탁업체가 개인정보를 안전하게 처리하도록 감독합니다.</p>
        <p>회사는 수탁업체나 위탁 업무의 내용이 변경될 경우, 이를 웹사이트에 공지(또는 서면·이메일·전화·SMS 등의 방법으로 개별 공지)합니다.</p>
        <div class="table-wrap">
          <table class="first-center">
            <thead>
              <tr>
                <th>수탁업체의 명칭</th>
                <th>위탁하는 업무의 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>제일기획</td>
                <td>□ 수집된 개인정보 저장 및 관리</td>
              </tr>
              <tr>
                <td>DFY</td>
                <td>□ 수집된 개인정보 관리, 경품 발송, 캠페인 사이트 개발 및 서버 관리</td>
              </tr>
              <tr>
                <td>1024</td>
                <td>□ 캠페인 사이트 서버 관리</td>
              </tr>
              <tr>
                <td>디플루이드</td>
                <td>□ 캠페인 사이트 개발 및 서버 관리</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>또한 일부 개인정보의 경우, 서비스 제공 및 이용자 편의 증진 등을 위하여 국외 업체에 위탁, 보관하고 있습니다.</p>
        <h3>&lt;국외 이전 사항&gt;</h3>
        <div class="table-wrap">
          <table class="text-center first-left second-left">
            <thead>
              <tr>
                <th class="col-2">업체 명칭</th>
                <th class="col-2">연락처</th>
                <th class="col-2_5">이전되는 항목</th>
                <th>이전 국가 <br>일시 및 방법</th>
                <th>이전받는 자의 이용 목적 및 <br>보유∙이용 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="col-2">Google <br>Inc.,</td>
                <td class="col-2">https://analytics.google.com/</td>
                <td>쿠키, 기기 브라우저 관련 데이터, <br>IP 주소, 사이트/앱 활동 <br>수집 (개인 식별 정보 미전송)</td>
                <td>미국∙웹사이트 내에 방문자 서비스 이용 시점에 네트워크를 통한 전송</td>
                <td>웹사이트에서 일어나는 사용자 상호작용에 대한 통계 측정용 (IP 주소 서비스 보안 제공 및 유지, 사용자 접속 국가 정보를 알려주는 역할), 사용자 및 이벤트 데이터 보관 38개월</td>
              </tr>
              <tr>
                <td class="col-2">Amazon Web <br>Services Inc.</td>
                <td class="col-2">https://aws.amazon.com/compliance/contact/</td>
                <td>수집된 모든 데이터</td>
                <td>미국∙웹사이트 내에 방문자 서비스 이용 시점에 네트워크를 통한 전송</td>
                <td>웹사이트 구축 유지 및 UGC 데이터 저장 목적, 데이터 보관 운영 종료 시점으로부터 1개월</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>10. 기타 개인정보 처리에 관한 방침</h2>
        <h3>(1) 개인정보 보호를 위한 기술적∙관리적 조치</h3>
        <p>회사는 이용자의 개인정보 처리 시 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적∙관리적 조치를 취합니다.</p>
        <p>– 내부관리계획의 수립 및 시행 <br>ㆍ개인정보를 안전하게 처리하기 위하여 내부관리계획을 수립하고 시행합니다. <br>ㆍ사내 개인정보 보호 전담기구 등을 통해 개인정보 보호조치의 이행사항 및 업무 담당자의 준수 여부를 확인하며, 문제 발견 시 즉시 시정하도록 조치합니다.</p>
        <p>– 접근 통제 장치의 설치 및 운영 <br>ㆍ침입 차단 시스템을 이용하여 외부의 무단 접근을 통제하고 있으며, 그 외에도 시스템상의 보안성을 확보하기 위하여 가능한 모든 기술적 장치를 갖추려고 노력합니다.</p>
        <p>– 접속 기록의 위조∙변조 방지를 위한 조치 <br>ㆍ개인정보 처리시스템에 접속한 기록을 보관∙관리하며, 접속 기록이 위조∙변조되지 않도록 보안 기능을 사용합니다.</p>
        <p>– 개인정보의 암호화 <br>ㆍ이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 보관 시 이를 암호화하거나 파일 잠금 기능(Lock)을 사용합니다. 또한 전송되는 데이터는 암호화되며, 중요한 데이터는 별도의 보안 기능으로 보호됩니다.</p>
        <p>– 해킹 예방 대책 <br>ㆍ백신 프로그램을 이용하여 악성 바이러스에 의한 피해를 방지합니다. 백신 프로그램은 주기적으로 업데이트되며, 갑작스런 바이러스가 출현할 경우 해당 백신이 나오는 즉시 이를 제공함으로써 개인정보 침해를 방지합니다. <br>ㆍ네트워크 상에서 개인정보를 안전하게 전송할 수 있는 암호화 전송 방법인 보안장치(Secure Socket Layer, SSL)를 채택하고 있습니다. <br>ㆍ해킹 등 외부침입에 대비하여 각 서버마다 침입 차단 시스템 및 취약점 분석 시스템 등을 도입하고 보안에 만전을 기합니다. <br>ㆍ개인정보와 일반 데이터를 섞어서 보관하지 않고, 별도의 서버에 분리하여 보관합니다.</p>
        <p>– 개인정보 취급 직원의 최소화 및 교육 <br>ㆍ이용자를 직접 상대하여 마케팅 업무를 수행하는 자, 개인정보 보호책임자 및 담당자 등 개인정보 관리 업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 등으로 이용자의 개인정보에 대한 접근 권한을 제한합니다. <br>ㆍ개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 및 개인정보 보호 의무 등에 관한 정기적인 사내교육 및 외부 위탁교육을 실시합니다. <br>ㆍ입사 시 전 직원이 보안서약서를 작성하게 함으로써 사람에 의한 정보 유출을 방지합니다. 또한 개인정보 보호조치의 이행사항 및 직원의 준수 여부를 감사하기 위한 내부 절차를 갖추고 있습니다. <br>ㆍ개인정보취급자의 업무 인수인계는 보안이 철저하게 유지된 상태에서 이뤄지며, 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화합니다. <br>ㆍ전산실 및 자료 보관실 등을 특별 보호구역으로 지정하여 출입을 통제합니다.</p>
        <h3>(2) 링크 제공 방침</h3>
        <p>회사는 다른 회사의 웹사이트 또는 자료에 대한 링크를 이용자에게 제공할 수 있으며, 이용자는 제3자가 개발한 제품 및 서비스를 삼성 서비스를 통해 사용할 수 있습니다 (예를 들어, 제3자가 개발한 어플리케이션을 삼성 갤럭시 스토어에서 다운로드하여 사용하는 경우 등). 이 경우, 회사는 제3자의 사이트, 자료, 제품 및 서비스에 대해 아무런 통제권이 없으므로, 제3자로부터 제공받는 제품 및 서비스나 자료의 유용성에 대해 책임지거나 보증할 수 없습니다. 회사가 제공한 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우, 해당 사이트의 개인정보 보호정책은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.</p>
        <h3>(3) 게시물 운영 방침 </h3>
        <p>회사는 이용자의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 다만, 아래의 경우는 예외로 합니다.</p>
        <p>– 스팸(spam)성 게시물 <br>– 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 게시물 <br>– 동의 없이 타인의 신상을 공개하는 게시물 <br>– 회사 또는 제3자의 지적재산권 등 권리를 침해하는 내용의 게시물 <br>– 기타 게시판 주제에 맞지 않는 게시물</p>
        <p>회사는 바람직한 게시판 문화를 조성하기 위하여 이용자가 타인의 동의 없이 그 신상을 공개하는 경우, 해당 게시물의 특정 부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다. 또한 해당 게시물의 내용이 다른 주제의 게시판에 적합하다면, 이를 옮긴 후 이동 경로를 밝혀 이용자가 오해하지 않도록 합니다.</p>
        <p>그 외의 경우는 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다.</p>
        <p>게시물과 관련된 제반 권리와 책임은 기본적으로 작성자 본인에게 있습니다. 또한 이용자가 게시물에서 자발적으로 공개한 정보는 보호받기 어려우므로 정보 공개 전에 심사 숙고하시기 바랍니다.</p>
        <h3>(4) 이메일 무단수집 거부 방침 </h3>
        <p>회사는 게시된 이메일 주소가 이메일 수집 프로그램 또는 그 밖의 기술적 장치를 통하여 무단 수집되는 것을 거부합니다. 이를 위반 시 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등에 의해 처벌 받을 수 있습니다.</p>
        <h3>(5) 광고성 정보의 전송</h3>
        <p>회사는 이용자의 사전 동의 없이 영리목적의 광고성 정보를 전송하지 않습니다.</p>
        <p style="margin-top: 48rem;">개인정보처리방침 버전 번호 : 1</p>
        <p>개인정보처리방침 시행 일자 : 10/7/2021</p>
      </template>
      <template v-else-if="state.country === 'brazil'">
        <div class="summary">
          <p>At Samsung Electronics Co., Ltd. (Data Controller) (“Samsung”), we know how important privacy is to our customers. Samsung is the data controller for #mySDCstack Campaign. We created this Privacy Notice to make sure you understand how we collect and use your personal information.</p>
          <p>Our Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> also applies to your use of #mySDCstack Campaign. Our Privacy Policy contains more information about how and for what purpose we use your data. It also includes information about your rights and how to contact us. Please read our Privacy Policy in addition to this Privacy Notice. However, this Privacy Notice shall always prevail over the Privacy Policy in relation to how we use your information for #mySDCstack Campaign.</p>
        </div>

        <h2>What information do we collect?</h2>
        <p>Through #mySDCstack Campaign, Samsung obtains and maintains information about you in various ways.</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information About Your Use of #mySDCstack Campaign</h3>
        <p>In addition to the information you provide, we will collect information about your use of our #mySDCstack Campaign through software on your devices and by other means.</p>
        <ul>
          <li>We may collect data about your use of #mySDCstack Campaign, including the time and duration, visited pages and clicked components and information about your device settings.</li>
        </ul>
        <p>We also may collect other information about you, your devices and apps, and your use of #mySDCstack Campaign in ways that we describe to you at the time of collection or otherwise with your consent.</p>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer the #mySDCstack Campaign website. To learn more about Google Analytics, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/.</a></p>

        <h2>How do we use your information?</h2>
        <h3>We use the information collected for the following purposes:</h3>
        <ul>
          <li>To provide you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>
        <p>We use and combine the information we collect about you from #mySDCstack Campaign with data from other services or features you use, your devices, and other sources such as SDC21-related official channels, as well as information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services for the purposes above.</p>
        <p>Samsung processes personal information for the purposes described above. We will obtain your consent for data processing in accordance with applicable law unless we have another legal basis to process your information without consent, as applicable, such as to comply with the law, regulatory obligations and legal processes, or to perform a contract that you have engaged with us. The legal basis may vary from one country to another, but we will rely on the appropriate legal basis provided in data protection and other laws of your country.</p>
        <div class="table-wrap">
          <table>
            <tbody>
              <tr>
                <td>Keeping our promise to you <br>(performance of contract or preliminary procedures) <br>LGPD Article 7, V</td>
                <td>• Providing you with the #mySDCstack Campaign services, such as user generated contents products, and registering or authenticating you or your device</td>
              </tr>
              <tr>
                <td>To promote our business interests <br>(legitimate interest) <br>LGPD Article 7, IX</td>
                <td>•	Responding to your questions or requests for information <br>•	Protecting the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities <br>•	Promoting and administering #mySDCstack Campaign and the #mySDCstack Campaign and related events and providing relevant event information to participants <br>•	Understanding and analyzing #mySDCstack Campaign participants’ online activity and browsing history <br>•	Protecting against, identifying and preventing fraud and other criminal activity, claims and other liabilities</td>
              </tr>
              <tr>
                <td>To comply with the law, regulatory <br>obligations and legal processes <br>LGPD Article 7, II, VI</td>
                <td>•	Complying with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy</td>
              </tr>
              <tr>
                <td>With your consent <br>LGPD Article 7, I</td>
                <td>•	Sending prizes to winners of the events <br>•	With respect to #mySDCstack Campaign participants, locating and reviewing user generated contents posted on participant’s social media in order to select winners</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>Who do we share your information with?</h2>
        <h3>We will disclose your information internally within our business and to the following entities, but only for the purposes described above.</h3>
        <ul>
          <li>Affiliates: other Samsung Electronics Group companies which we control or own;</li>
          <li>Business partners: partners who we work together with to provide you with #mySDCstack Campaign, such as Cheil Worldwide. These business partners control and manage your personal information;</li>
          <li>Service providers: carefully selected companies that provide services for or on behalf of us, such as Cheil Worldwide, AWS. These providers are also committed to protecting your information;</li>
          <li>Other parties when required by law or as necessary to protect #mySDCstack Campaign: for example, it may be necessary by law, legal process, or court order from governmental authorities to disclose your information. They may also seek your information from us for the purposes of law enforcement, national security, anti-terrorism, or other issues that are related to public security;</li>
          <li>Other parties in connection with corporate transactions: we may disclose your information to a third party as part of a merger or transfer, acquisition or sale, or in the event of bankruptcy; and</li>
          <li>Other parties with your consent or at your direction: in addition to the disclosures described in this Privacy Notice, we may share information about you with third parties when you separately consent to or request such sharing.</li>
        </ul>

        <h2>How long do we retain your information?</h2>
        <p>How long your data will be retained depends on the legal basis relied upon to process your data. For example, data we process to perform our contract with you requires us to keep the data throughout the time you use #mySDCstack Campaign. As long as you are an active user of #mySDCstack Campaign, we will retain and process this data. Data such as your account information and device information and identifiers fall into this category.</p>
        <p>Data we process to promote our business interests, such as your usage of #mySDCstack Campaign, is only kept for as long as needed for the purposes for which it was collected. For example, this data may be collected to perform analytics so we can develop improvements to #mySDCstack Campaign, or we may process data to keep a record of inquiries you made through or about #mySDCstack Campaign to improve your customer service experience.</p>
        <p>In addition, we may also process your data based on your consent. If you withdraw your consent, we will stop processing your data that relies on your consent, but it will not impact the processing of data collected prior to your withdrawal until the purposes for which such data was collected have been achieved.</p>
        <p>Please note, although we aim to retain your data for the time period described above, your data may be processed longer pursuant to applicable law. For example, if a specific statute mandates we require a certain piece of data, we will comply and retain that data until the required retention period expires.</p>
        <p>As long as your data is retained by us your data will always be subject to appropriate safeguards.</p>

        <h2>International data transfer</h2>
        <p>Your use of #mySDCstack Campaign will involve the transfer, storage, and processing of your personal information to other countries; such countries include, without limitation countries in the United States of America and the Republic of Korea which may not provide the same level of protection on personal data of your country. All international data transfers are subject to legal requirements to ensure that your personal information is processed safely and as you would expect.</p>

        <h2>Updates to this privacy notice</h2>
        <p>This Privacy Notice may be updated to let you know about changes in how we collect and process your information in #mySDCstack Campaign or changes in related laws. The date when the document was last updated is shown at the top of this Privacy Notice.</p>

        <h2>Your choices</h2>
        <p>We provide you with certain rights and choices in connection with the personal information we obtain about you in #mySDCstack Campaign in compliance with applicable law. To exercise your rights and manage your choices, please contact us as indicated in the CONTACT US section of this Privacy Notice.</p>

        <h2>Contact us</h2>
        <p>You can contact us to update your preferences, submit a request, or ask us questions. The easiest way to contact us is through the Contact Us section of: <a href="https://www.samsung.com/" target="_blank">https://www.samsung.com/.</a> For requests concerning your personal data, contact us  at the Privacy section of: <a href="https://www.samsung.com/" target="_blank">https://www.samsung.com/.</a></p>
        <p>You can also contact us here:</p>
        <p>Samsung Electronics Co., Ltd. <br>129, Samsung-ro, Yeongtong-gu, Suwon-si, Gyeonggi-do 16677, Republic of Korea</p>
      </template>
      <template v-else-if="state.country === 'eu'">
        <div class="summary">
          <p>At Samsung Electronics Co., Ltd. (“Samsung”), we know how important privacy is to our customers. Samsung is the data controller for #mySDCstack Campaign. We created this Privacy Notice to make sure you understand how we collect and use your personal information.</p>
          <p>Our Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> also applies to your use of #mySDCstack Campaign. Our Privacy Policy contains more information about how we use your data. It also includes information about your rights and how to contact us. Please read our Privacy Policy in addition to this Privacy Notice. However, this Privacy Notice shall always prevail over the Privacy Policy in relation to how we use your information for #mySDCstack Campaign.</p>
        </div>

        <h2>What information do we collect?</h2>
        <p>Through #mySDCstack Campaign, Samsung obtains and maintains information about you in various ways.</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information About Your Use of #mySDCstack Campaign</h3>
        <p>In addition to the information you provide, we may collect information about your use of #mySDCstack Campaign through software on your devices and by other means.</p>
        <ul>
          <li>We may collect data about your use of #mySDCstack Campaign, including the time and duration, visited pages and clicked components and information about your device settings.</li>
        </ul>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer #mySDCstack Campaign website. To learn more about Google Analytics, please visit https://www.google.com/policies/privacy/partners/.</p>

        <h2>How do we use your information?</h2>
        <h3>We use the information collected for the following purposes:</h3>
        <ul>
          <li>to provide you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>
        <p>We use and combine the information we collect about you from #mySDCstack Campaign with data from other services or features you use, your devices, and other sources such as SDC21-related official channels as well as information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services for the purposes above.</p>
        <h3>Samsung processes personal information for the purposes described above. Samsung’s legal basis to process personal information is outlined below:</h3>
        <div class="table-wrap">
          <table>
            <tbody>
              <tr>
                <td>Keeping our promising to you <br>(performance of contract) <br>GDPR Article 6(1)(b)</td>
                <td>• Providing you with #mySDCstack Campaign services, such as user generated contents products, and registering or authenticating you or your device</td>
              </tr>
              <tr>
                <td>To promote our business interests <br>(legitimate interest) <br>GDPR Article 6(1)(f)</td>
                <td>• Responding to your questions or requests for information <br>• Protecting the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal  <br>investigations and investigations by competent authorities <br>• Promoting and administering #mySDCstack Campaign and #mySDCstack Campaign and related events and providing relevant event information to participants <br>• Understanding and analyzing #mySDCstack Campaign participants’ online activity and browsing history <br>• Protecting against, identifying and preventing fraud and other criminal activity, claims and other liabilities</td>
              </tr>
              <tr>
                <td>To comply with the law, regulatory <br>obligations and legal processes <br>GDPR Article 6(1)(c)</td>
                <td>• Complying with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy</td>
              </tr>
              <tr>
                <td>With your consent <br>LGPD Article 7, I <br><br>Please note: you can withdraw your consent <br>at any time</td>
                <td>• Sending prizes to winners of the events <br>• With respect to #mySDCstack Campaign participants, locating and reviewing user generated contents posted on participant’s social media in order to select winners</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <h2>Who do we share your information with?</h2>
        <h3>We will disclose your information internally within our business and to the following entities, but only for the purposes described above.</h3>
        <ul>
          <li>Affiliates: other Samsung Electronics Group companies which we control or own;</li>
          <li>Business partners: partners who we work together with to provide you with #mySDCstack Campaign, such as Cheil Worldwide. These business partners control and manage your personal information;</li>
          <li>Service providers: carefully selected companies that provide services for or on behalf of us, such as Cheil Worldwide, AWS. These providers are also committed to protecting your information;</li>
          <li>Other parties when required by law or as necessary to protect #mySDCstack Campaign: for example, it may be necessary by law, legal process, or court order from governmental authorities to disclose your information. They may also seek your information from us for the purposes of law enforcement, national security, anti-terrorism, or other issues that are related to public security;</li>
          <li>Other parties in connection with corporate transactions: we may disclose your information to a third party as part of a merger or transfer, acquisition or sale, or in the event of bankruptcy; and</li>
          <li>Other parties with your consent or at your direction: in addition to the disclosures described in this Privacy Notice, we may share information about you with third parties when you separately consent to or request such sharing.</li>
        </ul>

        <h2>How long do we retain your information and where does it go?</h2>
        <p>How long your data will be retained depends on the legal basis relied upon to process your data. For example, data we process to perform our contract with you requires us to keep the data throughout the time you use #mySDCstack Campaign. As long as you are an active user of #mySDCstack Campaign, we will retain and process this data. Data such as user generated contents and your information (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting).</p>
        <p>Data we process to promote our business interests, such as your usage of #mySDCstack Campaign, is only kept for as long as needed for the purposes for which it was collected. For example, this data may be collected to perform analytics so we can develop improvements to #mySDCstack Campaign, or we may process data to keep a record of inquiries you made through or about #mySDCstack Campaign to improve your customer service experience.</p>
        <p>In addition, we may also process your data based on your consent. If you withdraw your consent, we will stop processing your data that relies on your consent, but it will not impact the processing of data collected prior to your withdrawal until the purposes for which such data was collected have been achieved.</p>
        <p>Please note, although we aim to retain your data for the time period described above, your data may be processed longer pursuant to applicable law. For example, if a specific statute mandates we require a certain piece of data, we will comply and retain that data until the required retention period expires.</p>
        <p>Your use of #mySDCstack Campaign will involve the transfer, storage, and processing of your personal information to other countries; such countries include, without limitation, countries in the European Economic Area, the United States of America and the Republic of Korea. All international data transfers are subject to legal requirements to ensure that your personal information is processed safely and as you would expect. Such measures include the use of Standard Contractual Clauses to safeguard the transfer of data outside of the EEA. To request more information or to obtain a copy of the contractual agreements in place, please see the CONTACT US section of this Privacy Notice.</p>

        <h2>How do we keep your information secure?</h2>
        <p>We take data protection very seriously. We have put in place physical and technical safeguards to keep the information we collect secure. However, please note that although we take reasonable steps to protect your information, no website, internet transmission, computer system or wireless connection is completely secure.</p>

        <h2>What are your rights?</h2>
        <p>Your personal information belongs to you. You can ask us to provide details about what we’ve collected and you can ask us to delete it or correct any inaccuracies. You can also ask us to restrict or limit the processing, sharing or transfer of your personal information, as well as to provide you with the personal information that we’ve collected so you can use it for your own purposes. However, requesting the deletion of your personal information may also result in a loss of access to #mySDCstack Campaign. We won’t delete data that we’re required by law to retain.</p>
        <p>To make a request concerning your rights or to make an inquiry, contact us using the methods outlined in the CONTACT US section.</p>

        <h2>Contact us</h2>
        <p>You can contact us to update your preferences, exercise your rights, submit a request or ask us questions.</p>
        <h3>You can also contact us at:</h3>
        <p>Data Controller <br>Samsung Electronics Co., Ltd <br>129, Samsung-ro, Yeongtong-gu <br>Suwon-si, Gyeonggi-do 16677, Republic of Korea</p>
        <p>Samsung Electronics has offices across Europe, so we can ensure that your request or query will be handled by the data protection team based in your region.</p>
        <p>The easiest way to contact us is through our Privacy Support Page at <a href="https://www.samsung.com/request-desk" target="_blank">https://www.samsung.com/request-desk.</a></p>
        <p>You can also contact us at: <br>European Data Protection Officer <br>Samsung Electronics (UK) Limited <br>Samsung House, 2000 Hillswood Drive, Chertsey, Surrey KT16 0RS, UK</p>
        <p>You can lodge a complaint with the relevant supervisory authority if you consider that our processing of your personal information infringes applicable law. Contact details for all EU supervisory authorities can be found at https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.</p>
        
        <h2>Updates to this privacy notice</h2>
        <p>This Privacy Notice may be updated to let you know about changes in how we collect and process your information in #mySDCstack Campaign or changes in related laws. The date when the document was last updated is shown at the top of this Privacy Notice.</p>
      </template>
      <template v-else-if="state.country === 'latin'">
        <div class="summary">
          <p>At Samsung Electronics Co., Ltd. (Data Controller) (“Samsung”), we know how important privacy is to our customers. Samsung is the data controller for #mySDCstack Campaign. We created this Privacy Notice to make sure you understand how we collect and use your personal information.</p>
          <p>Our Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> also applies to your use of #mySDCstack Campaign. Our Privacy Policy contains more information about how and for what purpose we use your data. It also includes information about your rights and how to contact us. Please read our Privacy Policy in addition to this Privacy Notice. However, this Privacy Notice shall always prevail over the Privacy Policy in relation to how we use your information for #mySDCstack Campaign.</p>
        </div>

        <h2>What information do we collect?</h2>
        <p>Through #mySDCstack Campaign, Samsung obtains and maintains information about you in various ways.</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information About Your Use of #mySDCstack Campaign</h3>
        <p>In addition to the information you provide, we will collect information about your use of our #mySDCstack Campaign through software on your devices and by other means.</p>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <p>We also may collect other information about you, your devices and apps, and your use of #mySDCstack Campaign in ways that we describe to you at the time of collection or otherwise with your consent.</p>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer the #mySDCstack Campaign website. To learn more about Google Analytics, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/.</a></p>

        <h2>How do we use your information?</h2>
        <h3>We use the information collected for the following purposes:</h3>
        <ul>
          <li>To provide you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>
        <p>We use and combine the information we collect about you from #mySDCstack Campaign with data from other services or features you use, your devices, and other sources such as SDC21-related official channels, as well as information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services for the purposes above.</p>
        <p>Samsung processes personal information for the purposes described above. We will obtain your consent for data processing in accordance with applicable law unless we have another legal basis to process your information without consent, as applicable, such as to comply with the law, regulatory obligations and legal processes, or to perform a contract that you have engaged with us. The legal basis may vary from one country to another, but we will rely on the appropriate legal basis provided in data protection and other laws of your country.</p>

        <h2>Who do we share your information with?</h2>
        <h3>We will disclose your information internally within our business and to the following entities, but only for the purposes described above.</h3>
        <ul>
          <li>Affiliates: other Samsung Electronics Group companies which we control or own;</li>
          <li>Business partners: partners who we work together with to provide you with #mySDCstack Campaign, such as Cheil Worldwide. These business partners control and manage your personal information;</li>
          <li>Service providers: carefully selected companies that provide services for or on behalf of us, such as Cheil Worldwide, AWS. These providers are also committed to protecting your information;</li>
          <li>Other parties when required by law or as necessary to protect #mySDCstack Campaign: for example, it may be necessary by law, legal process, or court order from governmental authorities to disclose your information. They may also seek your information from us for the purposes of law enforcement, national security, anti-terrorism, or other issues that are related to public security;</li>
          <li>Other parties in connection with corporate transactions: we may disclose your information to a third party as part of a merger or transfer, acquisition or sale, or in the event of bankruptcy; and</li>
          <li>Other parties with your consent or at your direction: in addition to the disclosures described in this Privacy Notice, we may share information about you with third parties when you separately consent to or request such sharing.</li>
        </ul>

        <h2>How long do we retain your information?</h2>
        <p>How long your data will be retained depends on the legal basis relied upon to process your data. For example, data we process to perform our contract with you requires us to keep the data throughout the time you use #mySDCstack Campaign. As long as you are an active user of #mySDCstack Campaign, we will retain and process this data. Data such as your account information and device information and identifiers fall into this category.</p>
        <p>Data we process to promote our business interests, such as your usage of #mySDCstack Campaign, is only kept for as long as needed for the purposes for which it was collected. For example, this data may be collected to perform analytics so we can develop improvements to #mySDCstack Campaign, or we may process data to keep a record of inquiries you made through or about #mySDCstack Campaign to improve your customer service experience.</p>
        <p>In addition, we may also process your data based on your consent. If you withdraw your consent, we will stop processing your data that relies on your consent, but it will not impact the processing of data collected prior to your withdrawal until the purposes for which such data was collected have been achieved.</p>
        <p>Please note, although we aim to retain your data for the time period described above, your data may be processed longer pursuant to applicable law. For example, if a specific statute mandates we require a certain piece of data, we will comply and retain that data until the required retention period expires.</p>
        <p>As long as your data is retained by us your data will always be subject to appropriate safeguards.</p>

        <h2>International data transfer</h2>
        <p>Your use of #mySDCstack Campaign will involve the transfer, storage, and processing of your personal information to other countries; such countries include, without limitation countries in the United States of America and the Republic of Korea which may not provide the same level of protection on personal data of your country. All international data transfers are subject to legal requirements to ensure that your personal information is processed safely and as you would expect.</p>

        <h2>Updates to this privacy notice</h2>
        <p>This Privacy Notice may be updated to let you know about changes in how we collect and process your information in #mySDCstack Campaign or changes in related laws. The date when the document was last updated is shown at the top of this Privacy Notice.</p>

        <h2>Your choices</h2>
        <p>We provide you with certain rights and choices in connection with the personal information we obtain about you in #mySDCstack Campaign in compliance with applicable law. To exercise your rights and manage your choices, please contact us as indicated in the CONTACT US section of this Privacy Notice.</p>

        <h2>Contact us</h2>
        <p>You can contact us to update your preferences, submit a request, or ask us questions. The easiest way to contact us is through the Contact Us section of: <a href="https://www.samsung.com/" target="_blank">https://www.samsung.com/.</a> For requests concerning your personal data, contact us  at the Privacy section of: <a href="https://www.samsung.com/" target="_blank">https://www.samsung.com/.</a></p>
        <p>You can also contact us here:</p>
        <p style="margin-top: 24rem;">Samsung Electronics Co., Ltd. <br>129, Samsung-ro, Yeongtong-gu, Suwon-si, Gyeonggi-do 16677, Republic of Korea</p>
      </template>
      <template v-else-if="state.country === 'turkey'">
        <div class="summary">
          <p>At Samsung Electronics Co., Ltd. (“Samsung”), we know how important privacy is to our customers. Samsung is the data controller for #mySDCstack Campaign. The Data Controller Representative of Samsung in Turkey is “Samsung Electronics Istanbul Pazarlama ve Ticaret Ltd. Şti.”. We created this Privacy Notice (“KVKK Notification Text”) to make sure you understand how we collect and use your personal information.</p>
          <p>Samsung aims to process personal data of #mySDCstack Campaign users in accordance with the international data protection provisions and the provisions of the Law No. 6698 on Protection of Personal Data (the “KVKK- also known as LPPD in English abbreviation”) and other legislation.</p>
        </div>

        <h3>We hereby inform you that your personal data that you have provided/will provide to Samsung and/or are obtained externally by any means by Samsung due to your being a #mySDCstack Campaign user shall be processed by Samsung acting as of “Data Controller”:</h3>
        <ul>
          <li>within the framework of the purpose for which they are required to be processed and as relevant, limited, and proportionate to such purpose,</li>
          <li>by preserving the accuracy and the most up-to-date state of such personal data as they have been provided to Samsung by you or otherwise,</li>
          <li>and by complying with the conditions stipulated in the KVKK.</li>
        </ul>
        <p>With this KVKK Notification Text (Obligation to Inform Text), it has been aimed to continue and develop the activities carried out by Samsung in accordance with the principles contained in the KVKK.</p>
        <p>Our Samsung Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> also applies to your use of #mySDCstack Campaign. Our Privacy Policy contains more information about how we use your data. It also includes information about your rights and how to contact us. Please read our Privacy Policy in addition to this KVKK Notification Text. However, this KVKK Notification Text shall always prevail over the Privacy Policy in relation to how we use your information for #mySDCstack Campaign.</p>

        <h2>What information do we collect?</h2>
        <p>Samsung shall process your personal data for the purposes and legal grounds specified in this KVKK Notification Text. If there is any change in the purpose for which your personal data are processed or in the legal grounds thereof, this KVKK Notification Text shall be updated and re-published by Samsung.</p>
        <p>Through #mySDCstack Campaign, Samsung obtains and maintains information about you in various ways. Your personal data are collected through electronic media, via all kinds of information, documents and certificates you have submitted to Samsung and collected through the application, both before and after the establishment of the contractual relationship and during such contractual relationship.</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information About Your Use of #mySDCstack Campaign</h3>
        <p>In addition to the information you provide, we will collect information about your use of our #mySDCstack Campaign through software on your devices and by other means.</p>
        <ul>
          <li>We may collect data about your use of #mySDCstack Campaign, including the time and duration, visited pages and clicked components and information about your device settings.</li>
        </ul>
        <p>We also may collect other information about you, your devices and apps, and your use of #mySDCstack Campaign in ways that we describe to you at the time of collection or otherwise with your consent.</p>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer the #mySDCstack Campaign website. To learn more about Google Analytics, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/.</a></p>
        
        <h2>How do we use your information?</h2>
        <h3>We use the information collected for the following purposes:</h3>
        <ul>
          <li>Providing you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this KVKK Notification Text  and our Privacy Policy;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>
        <h3>Samsung processes personal information for the purposes described above. Samsung’s legal basis to process personal information is outlined below:</h3>
        <p>Your personal data are processed being limited to the use of the related service/platform; in compliance with the legislation in force and the Law on Protection of Personal Data; by automated or non-automated methods;</p>
        <ul>
          <li>based on Article 5, paragraph 2 of the KVKK, sub-paragraph c), which stipulates ‘if it is necessary to process personal data of contractual parties, provided that such processing is directly related to the establishment or performance of the contract’, and sub-paragraph f) thereof, which stipulates ‘if it is mandatory to process personal data for the legitimate interests of the data controller, provided that the fundamental rights and freedoms of the data subject are not violated’:</li>
          <li>for all collection purposes stated above other than sending commercial electronic messages to you, customizing and offering our products and services according to your satisfaction, usage habits and needs, and carrying out other sales and marketing activities;</li>
        </ul>
        <p>and may be updated in line with our obligations arising from the legislation and the company policies.</p>
        <p>Your personal data shall be kept for a reasonable period of time until the purpose for which they are processed ceases to exist or until the end of the legal time period specified in the relevant legislation, and in any case until the expiry of the statute of limitations.</p>

        <h2>Who do we share your information with?</h2>
        <p>The procedures and principles to be applicable to personal data transfers are set forth in Articles 8 and 9 of the KVKK, and the personal data of the data subject may be transferred to third parties in Turkey and/or abroad. <br>We will disclose your information internally within our business and to the following entities, but only for the purposes described above.</p>
        <ul>
          <li>Affiliates: other Samsung Electronics Group companies which we control or own</li>
          <li>Business partners: partners who we work together with to provide you with #mySDCstack Campaign, such as Cheil Worldwide. These business partners control and manage your personal information;</li>
          <li>Service providers: carefully selected companies that provide services for or on behalf of us, such as Cheil Worldwide, AWS. These providers are also committed to protecting your information;</li>
          <li>Other parties when required by law or as necessary to protect #mySDCstack Campaign: for example, it may be necessary by law, legal process, or court order from governmental authorities to disclose your information. They may also seek your information from us for the purposes of law enforcement, national security, anti-terrorism, or other issues that are related to public security;</li>
          <li>Other parties in connection with corporate transactions: we may disclose your information to a third party as part of a merger or transfer, acquisition or sale, or in the event of bankruptcy; and</li>
          <li>Other parties with your consent or at your direction: in addition to the disclosures described in this KVKK Notification Text, we may share information about you with third parties when you separately consent to or request such sharing.</li>
        </ul>

        <h2>How long do we retain your information and where does it go?</h2>
        <p>How long your data will be retained depends on the legal basis relied upon to process your data. For example, data we process to perform our contract with you requires us to keep the data throughout the time you use #mySDCstack Campaign. As long as you are an active user of #mySDCstack Campaign, we will retain and process this data. Data such as your account information and device information and identifiers fall into this category.</p>
        <p>Data we process to promote our business interests, such as your usage of #mySDCstack Campaign, is only kept for as long as needed for the purposes for which it was collected. For example, this data may be collected to perform analytics so we can develop improvements to #mySDCstack Campaign, or we may process data to keep a record of inquiries you made through or about #mySDCstack Campaign to improve your customer service experience.</p>
        <p>Please note, although we aim to retain your data for the time period described above, your data may be processed longer pursuant to applicable law. For example, if a specific statute mandates we require a certain piece of data, we comply and retain that data until the required retention period expires.</p>
        <p>As long as your data is retained by us your data will always be subject to appropriate safeguards.</p>
        <p>Your use of #mySDCstack Campaign will involve the transfer, storage, and processing of your personal information to other countries; such countries include, without limitation, [European Economic Area, the United States of America and the Republic of Korea] .All international data transfers are subject to legal requirements to ensure that your personal information is processed safely and as you would expect.</p>

        <h2>Your rights under KVKK</h2>
        <h3>Data Subject’s Rights under KVKK <br>In accordance with Article 11 of the KVKK, you may apply to Samsung and make requests on the following issues about your personal data:</h3>
        <p>a.  Learning whether or not your personal data have been processed, <br>b. Obtaining information on the procedure, if your personal data have been processed,  <br>c. Learning the purpose for which your personal data have been processed and whether or not they are used in line with such purpose,  <br>d. Obtaining information about third parties to whom your personal data are transferred within Turkey or abroad, <br>e. Requesting correction of your personal data if they have been processed incompletely or inaccurately and requesting the notification of third parties to whom your personal data are transferred of the correction made in this respect, <br>f. Requesting deletion, destruction, or anonymization of your personal data if the reasons for which they are processed no longer exist and requesting the notification of third parties to whom your personal data are transferred of such deletion, destruction, or anonymization procedure, <br>g. Objecting to the occurrence of a result which is detrimental to you as a result of analyzing of your processed personal data exclusively through automatic systems, <br>h. Requesting indemnification for your damages which are caused by unlawful processing of your personal data.</p>
        <p>Samsung or our Data Controller Representative shall receive and evaluate the data subject requests through the “Samsung Electronics Co., Ltd. Data Subject Application Form” available on https://www.samsung.com/tr/info/kvkk/. Samsung or our Data Controller Representative shall conclude your application free of charge in accordance with Article 13 of the KVKK according to the nature of the request, and within 30 (thirty) days at the latest. If the request is rejected, the reason(s) for rejection shall be notified to you in writing or electronically together with the relevant grounds.</p>

        <h2>Updates to this KVKK Notification Text</h2>
        <p>This KVKK Notification Text may be updated to let you know about changes in how we collect and process your information in #mySDCstack Campaign or changes in related laws. The date when the document was last updated is shown at the top of this KVKK Notification Text. You may access the most up-to-date version of this KVKK Notification Text on <a href="https://sdc21mystack.com/?view=privacy" target="_blank">https://sdc21mystack.com/?view=privacy</a></p>
      </template>
      <template v-else>
        <div class="summary">
          <p>At Samsung Electronics Co., Ltd., we know how important privacy is to our customers. We created this Privacy Notice to make sure you understand how we collect and use your personal information.</p>
          <p>Our Privacy Policy at <a href="https://account.samsung.com/membership/terms/privacypolicy" target="_blank">https://account.samsung.com/membership/terms/privacypolicy</a> also applies to your use of #mySDCstack Campaign. Our Privacy Policy contains more information about how we use your data. It also includes information about your rights and how to contact us. Please read our Privacy Policy in addition to this Privacy Notice. However, this Privacy Notice shall always prevail over the Privacy Policy in relation to how we use your information for #mySDCstack Campaign.</p>
        </div>
        
        <h2>What information do we collect?</h2>
        <p>Through #mySDCstack Campaign, we obtain and maintain information about you in various ways.</p>
        <h3>Information You Provide Directly</h3>
        <ul>
          <li>We may collect personal information that you provide, such as your name, address and phone number (if you win a prize), e-mail address and contact details, social media ID (if your user generated content is selected for reposting) and any communications you send or deliver to us.</li>
        </ul>
        <h3>Information About Your Use of #mySDCstack Campaign</h3>
        <p>In addition to the information you provide, we will collect information about your use of our #mySDCstack Campaign website through software on your devices and by other means.</p>
        <ul>
          <li>We may collect data about your use of #mySDCstack Campaign, including the time and duration, visited pages and clicked components and information about your device settings.</li>
        </ul>
        <p>We also may collect other information about you, your devices and apps, and your use of #mySDCstack Campaign in ways that we describe to you at the time of collection or otherwise with your consent.</p>
        <p>We may collect personal information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services. We may use third-party analytics services, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers and other relevant third parties who use the information, for example, to evaluate use of #mySDCstack Campaign and to help administer #mySDCstack Campaign. To learn more about Google Analytics, please visit <a href="https://www.google.com/policies/privacy/partners/" target="_blank">https://www.google.com/policies/privacy/partners/.</a></p>

        <h2>How do we use your information?</h2>
        <h3>We use the information collected for the following purposes:</h3>
        <ul>
          <li>to provide you with #mySDCstack Campaign services, such as user generated contents products, and to register or authenticate you or your device;</li>
          <li>to respond to your questions or requests for information;</li>
          <li>to protect the rights, property, or safety of Samsung, or any of our respective affiliates, business partners, employees or customers, for example, in legal proceedings, internal investigations and investigations by competent authorities;</li>
          <li>to promote and administer #mySDCstack Campaign and #mySDCstack Campaign and related events and to provide relevant event information to participants;</li>
          <li>to send prizes to winners of the events;</li>
          <li>with respect to #mySDCstack Campaign stack campaign participants, to locate and to review user generated contents posted on participant’s social media in order to select winners;</li>
          <li>to understand and analyze #mySDCstack Campaign participants’ online activity and browsing history;</li>
          <li>to protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;</li>
          <li>to comply with and enforce applicable legal requirements, relevant industry standards and our policies, including this Privacy Notice and our Privacy Policy;</li>
          <li>otherwise with your separate consent where required by applicable law or as described to at the time your information is collected.</li>
        </ul>
        <p>We use and combine the information we collect about you from #mySDCstack Campaign with data from other services or features you use, your devices, and other sources such as SDC21-related official channels, as well as information about your online activities on websites and connected devices over time and across third-party websites, devices, apps and other online features and services for the purposes above.</p>

        <h2>Who do we share your information with?</h2>
        <h3>We will disclose your information internally within our business and to the following entities, but only for the purposes described above.</h3>
        <ul>
          <li>Affiliates: other Samsung Electronics Group companies which we control or own;</li>
          <li>Business partners: partners who we work together with to provide you with #mySDCstack Campaign, such as Cheil Worldwide. These business partners control and manage your personal information;</li>
          <li>Service providers: carefully selected companies that provide services for or on behalf of us, such as Cheil Worldwide, AWS. These providers are also committed to protecting your information;</li>
          <li>Other parties when required by law or as necessary to protect #mySDCstack Campaign: for example, it may be necessary by law, legal process, or court order from governmental authorities to disclose your information. They may also seek your information from us for the purposes of law enforcement, national security, anti-terrorism, or other issues that are related to public security;</li>
          <li>Other parties in connection with corporate transactions: we may disclose your information to a third party as part of a merger or transfer, acquisition or sale, or in the event of bankruptcy; and</li>
          <li>Other parties with your consent or at your direction: in addition to the disclosures described in this Privacy Notice, we may share information about you with third parties when you separately consent to or request such sharing.</li>
        </ul>
        <p>Your use of the #mySDCstack Campaign will involve the transfer, storage, and processing of your personal information to other countries; such countries include, without limitation, the Republic of Korea and the United States of America. All international data transfers are subject to legal requirements to ensure that your personal information is processed safely and as you would expect.</p>

        <h2>Updates to this privacy notice</h2>
        <p>This Privacy Notice may be updated to let you know about changes in how we collect and process your information in #mySDCstack Campaign or changes in related laws. The date when the document was last updated is shown at the top of this Privacy Notice.</p>
      </template>
    </div>
  </div>
</template>

<script>
import { useStore, ref, onMounted } from '@/helper/vue.js'

export default {
  name: 'PrivacyPolicy',
  setup() {
    const { state, commit } = useStore()
    const isSelectorOpen = ref(false)

    onMounted(() => {
      const latin = ['es-GT', 'es-NI', 'es-DO', 'es-MX', 'es-VE', 'es-BO', 'es-AR', 'es-EC', 'qu-EC', 'es-SV', 'es-HN', 'es-UY', 'en-JM', 'es-CL', 'es-CR', 'es-CO', 'en-TT', 'es-PA', 'es-PY', 'qu-PE']
      const brazil = ['pt-BR']
      const turkey = ['tr', 'tr-TR']
      const korea = ['ko', 'ko-KR']
      const us = ['en-US']
      const eu = ['de-AT', 'fr-BE', 'nl-BE', 'bg', 'bg-BG', 'cs', 'cs-CZ', 'de-DE', 'da-DK', 'et', 'et-EE', 'es-ES', 'fi-FI', 'sv-FI', 'fr-FR', 'el', 'el-GR', 'hr', 'hr-HR', 'hu-HU', 'en-IE', 'it-IT', 'lt', 'lt-LT', 'de-LU', 'fr-LU', 'lv', 'lv-LV', 'mt-MT', 'nl-NL', 'pl-PL', 'pt-PT', 'ro', 'ro-RO', 'sv-SE', 'sl', 'sl-SI', 'sk', 'sk-SK']
      if (navigator.language) {
        if (latin.find(item => item === navigator.language)) {
          commit('changeCountry', 'latin')
        } else if (brazil.find(item => item === navigator.language)) {
          commit('changeCountry', 'brazil')
        } else if (turkey.find(item => item === navigator.language)) {
          commit('changeCountry', 'turkey')
        } else if (korea.find(item => item === navigator.language)) {
          commit('changeCountry', 'korea')
        } else if (us.find(item => item === navigator.language)) {
          commit('changeCountry', 'us')
        } else if (eu.find(item => item === navigator.language)) {
          commit('changeCountry', 'eu')
        }
      }
    })

    return {
      state,
      commit,
      isSelectorOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 100%;
  font-family: 'SamsungOne', sans-serif;
  font-size: 0.069444444vw;
  text-align: left;
  border-radius: 10rem;
  background-color: #fff;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    font-size: 0.266666667vw;
  }
  &__body {
    height: 100%;
    padding: 84rem 78rem 76rem;
    overflow: auto;

    @media only screen and (max-width: 600px) {
      padding: 54rem 32rem;
    }
  }
  .table-wrap {
    overflow: auto;
  }
  table {
    width: 100%;
    min-width: 600px;
    margin-top: 12rem;
    border: solid 1px #333;
    th, td {
      padding: 16rem 22rem;
      font-size: 16em;
      font-weight: bold;
      line-height: 1.5;
      color: #333;
      border: solid 1px #333;

      @media only screen and (max-width: 600px) {
        line-height: 1.31;
      }

      &.col-1 {
        width: 8.33333%;
        word-break: break-all;
      }
      &.col-2 {
        width: 16.66666%;
        word-break: break-all;
      }
      &.col-2_5 {
        width: 20.833325%;
      }
      &.col-3 {
        width: 24.99999%;
      }
      &.col-4 {
        width: 33.33332%;
      }
      &.col-5 {
        width: 41.66665%;
      }
      &.col-6 {
        width: 50%;
      }
      &.col-7 {
        width: 58.33331%;
      }
      &.col-8 {
        width: 66.66664%;
      }
      &.col-9 {
        width: 74.99997%;
      }
      &.col-10 {
        width: 83.3333%;
      }
      &.col-11 {
        width: 91.66663%;
      }
      &.col-12 {
        width: 100%;
      }
    }
    th {
      text-align: center;
    }
    &.text-center {
      td {
        text-align: center;
      }
    }
    &.first-left {
      td:nth-of-type(1) {
        text-align: left;
      }
    }
    &.second-left {
      td:nth-of-type(2) {
        text-align: left;
      }
    }
    &.first-center {
      td:nth-of-type(1) {
        text-align: center;
      }
    }
  }
  h1 {
    margin-bottom: 38rem;
    font-family: 'SamsungSharpSans', sans-serif;
    font-size: 48em;
    font-weight: bold;
    line-height: 0.96;
    color: #000;

    @media only screen and (max-width: 600px) {
      margin-bottom: 24rem;
      font-size: 24em;
      line-height: 1.5;
    }
  }
  h2 {
    padding-top: 48rem;
    font-family: 'SamsungSharpSans', sans-serif;
    margin-bottom: 24rem;
    font-size: 38em;
    font-weight: bold;
    line-height: 1.21;
    color: #000;

    span {
      display: block;
      margin: 12rem 0 0 2em;
      font-size: 0.47368em;
      line-height: 1.33;
      color: #333;
    }

    @media only screen and (max-width: 600px) {
      margin-bottom: 16rem;
      font-size: 20em;
      line-height: 1.4;

      span {
        margin-left: 0;
        font-size: 0.8em;
      }
    }
    & + h3 {
      padding-top: 0;
    }
  }
  h3 {
    padding-top: 22rem;
    margin-bottom: 12rem;
    font-size: 16em;
    font-weight: bold;
    line-height: 1.5;
    color: #000;

    @media only screen and (max-width: 600px) {
      line-height: 1.31;
    }
  }
  p, li {
    font-size: 16em;
    line-height: 1.5;
    color: #333;
    word-break: break-word;

    @media only screen and (max-width: 600px) {
      line-height: 1.31;
    }
  }
  p {
    margin-top: 12rem;
    &.effective {
      display: inline-block;
      width: 262rem;
      padding: 13rem 0 11rem;
      margin: 0 0 20rem;
      font-size: 20em;
      font-weight: bold;
      line-height: 1.2;
      color: #2f2f2f;
      border-radius: 24rem;
      border: solid 1px #000;
      text-align: center;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 14rem;
      &.effective {
        width: 229rem;
        padding: 11rem 19rem 9rem;
        margin-bottom: 10rem;
        font-size: 18rem;
        line-height: 1.33;
        white-space: nowrap;
      }
    }
  }
  .select-country {
    position: relative;
    display: inline-block;
    width: 317rem;
    margin: 0 24rem 20rem 0;
    border-radius: 24rem;
    border: solid 1px #000;
    &__current {
      width: 100%;
      padding: 13rem 24rem 11rem;
      font-family: 'SamsungOne', sans-serif;
      font-size: 20em;
      font-weight: bold;
      line-height: 1.2;
      color: #2f2f2f;
      text-align: left;
      &-icon {
        position: absolute;
        top: 50%;
        right: 24rem;
        width: 24rem;
        height: 24rem;
        margin-top: -12rem;
        z-index: 100;
      }
    }
    &__option {
      position: absolute;
      top: -1px;
      right: -1px;
      left: -1px;
      padding: 1px 0;
      margin: 0;
      background-color: #fff;
      border: solid 1px #717171;
      li {
        font-size: 20em;
        font-weight: bold;
        line-height: 2.4;
        color: #2f2f2f;
        list-style: none;
        &::before {
          content: none;
        }
        span {
          display: block;
          padding: 0 24rem;
          cursor: pointer;
          &.active {
            color: #1428a0;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      width: 287rem;
      margin: 0 0 10rem;
      &__current {
        padding: 11rem 19rem 9rem;
        font-size: 18rem;
        line-height: 1.33;
        &-icon {
          right: 19rem;
        }
      }
      &__option {
        li {
          font-size: 18rem;
          span {
            padding: 0 19rem;
          }
        }
      }
    }
  }
  ul, ol, h3 + p {
    margin-top: 12rem;
  }
  ul li::before {
    content: '•';
    margin-right: .5em;
  }
  ol li {
    list-style: decimal;
    list-style-position: inside;
  }
  strong {
    font-weight: bold;
  }
  a {
    font-weight: bold;
    color: #1428a0;
    word-break: break-all;
    word-break: break-word;
    text-decoration: underline;
    &.download {
      position: relative;
      display: inline-block;
      padding-right: 22rem;
      margin: 24rem 0 10rem;
      font-size: 18em;
      font-weight: bold;
      line-height: 1.33;
      color: #1428a0;
      text-decoration: none;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 20rem;
        height: 100%;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('../assets/landing/icon-download@2x.png');
      }
    }
  }
  &.korean {
    .container__body {
      font-family: 'SamsungOneKorean', sans-serif;
      .effective {
        font-family: 'SamsungOne', sans-serif;
      }
      h1, h2 {
        font-family: 'SamsungOneKorean', sans-serif;
      }
    }
  }
}
</style>